<template>
    <div class="col-12">
        <form class="form-horizontal" role="form" @submit.prevent="submitForm">
            <div class="row">
                <div class="col-6">
                    <b-form-group id="form.client.labels.firstName" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.client.labels.firstName')" label-for="form.client.labels.firstName">
                        <b-form-input id="form.client.labels.firstName" v-model="firstNameField"
                            :invalid-feedback="firstNameValidationMessage" :state="!isFirstNameValid ? false : null"
                            @keyup="validateFirstName()"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group id="form.client.labels.lastName" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.client.labels.lastName')">
                        <b-form-input id="form.client.labels.lastName" v-model="lastNameField"
                            :invalid-feedback="lastNameValidationMessage" :state="!isLastNameValid ? false : null"
                            @keyup="validateLastName()"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group id="form.client.labels.phone" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.client.labels.phone')">
                        <b-form-input id="form.client.labels.phone" v-model="phoneField"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group id="form.client.labels.phone2" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.client.labels.phone2')">
                        <b-form-input id="form.client.labels.phone2" v-model="phoneTwoField"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group id="form.client.labels.email" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.client.labels.email')">
                        <b-form-input id="form.client.labels.email" v-model="emailField"
                            :invalid-feedback="emailValidationMessage" :state="!isEmailValid ? false : null"
                            @keyup="validateEmail()"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <ModalSelectAgent :fieldLabel="$t('form.client.labels.agent')" :emptyLabel="$t('constants.NONE')"
                        :item="agentField" @selectItem="selectAgent" :discardItem="agentId"></ModalSelectAgent>
                </div>
                <div class="col-12">
                <br>
                </div>
                <div class="col-6">
                    <ModalSelectCountry :fieldLabel="$t('form.client.labels.country')" :emptyLabel="$t('constants.NONE')"
                        :item="countryField" @selectItem="selectCountry" :discardItem="countryId"></ModalSelectCountry>
                </div>
                <div class="col-6" v-if="countryId">
                    <ModalSelectCity :fieldLabel="$t('form.client.labels.city')" :emptyLabel="$t('constants.NONE')"
                        :item="cityField" @selectItem="selectCity" :discardItem="cityId" :country="countryField"></ModalSelectCity>
                </div>
                <div class="col-12">
                <br>
                </div>
                <div class="col-6" v-if="cityId">
                    <ModalSelectMunicipality :fieldLabel="$t('form.client.labels.municipality')" :emptyLabel="$t('constants.NONE')"
                        :item="municipalityField" @selectItem="selectMunicipality" :discardItem="municipalityId" :city="cityField"></ModalSelectMunicipality>
                </div>
                <div class="col-6">
                    <b-form-group id="form.client.labels.address" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.client.labels.address')">
                        <b-form-input id="form.client.labels.address" v-model="addressField"
                            :invalid-feedback="addressValidationMessage" :state="!isAddressValid ? false : null"
                            @keyup="validateAddress()"></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <b-button variant="primary" type="submit" :disabled="formValidationButton">{{ $t('buttons.save')
                }}</b-button>
        </form>
    </div>
</template>

<script>
import ModalSelectAgent from "@/views/pages/agent/modal-select.vue"
import ModalSelectCountry from "@/views/pages/country/modal-select-country.vue"
import ModalSelectCity from "@/views/pages/country/modal-select-city.vue"
import ModalSelectMunicipality from "@/views/pages/country/modal-select-municipality.vue"

export default {
    components: {
        ModalSelectAgent,ModalSelectCountry,ModalSelectCity,ModalSelectMunicipality
    },
    data() {
        return {
            firstNameField: "",
            lastNameField: "",
            emailField: "",
            phoneField: "",
            phoneTwoField: "",
            addressField: "",
            clientId: null,
            agentField: null,
            agentId: null,
            countryId:null,
            countryField:null,
            cityId:null,
            cityField:null,
            municipalityField:null,
            municipalityId:null,

            isFirstNameValid: true,
            firstNameValidationMessage: null,
            isLastNameValid: true,
            lastNameValidationMessage: null,
            isEmailValid: true,
            emailValidationMessage: null,
            isAddressValid: true,
            addressValidationMessage: null,
            formValidationButton: true,

        };
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: ''
        }
    },
    watch: {
        'item'() {
            if (this.item) {
                if (this.item.id) {
                    this.clientId = this.item.id
                }
                if (this.item.first_name) {
                    this.firstNameField = this.item.first_name
                }
                if (this.item.last_name) {
                    this.lastNameField = this.item.last_name
                }
                if (this.item.email) {
                    this.emailField = this.item.email
                }
                if (this.item.phone) {
                    this.phoneField = this.item.phone
                }
                if (this.item.phone2) {
                    this.phoneTwoField = this.item.phone2
                }
                if (this.item.address) {
                    this.addressField = this.item.address
                }
                if (this.item.owner) {
                    this.agentField = this.item.owner
                    this.agentId = this.item.owner.id
                }
                if (this.item.country) {
                    this.countryField = this.item.country
                    this.countryId = this.item.country.id
                }

                if (this.item.city) {
                    this.cityField = this.item.city
                    this.cityId = this.item.city.id
                }

                if (this.item.municipality) {
                    this.municipalityField = this.item.municipality
                    this.municipalityId = this.item.municipality.id
                }

                
            }

            var isFirstNameValid = this.firstNameField !== null && this.firstNameField.length > 0;

            var isLastNameValid = this.lastNameField !== null && this.lastNameField.length > 0;
            var re = /\S+@\S+\.\S+/;
            var isEmailValid = re.test(this.emailField)
            var isAddressValid = this.addressField !== null && this.addressField.length > 0;

            var isAgentValid = this.agentField != null
            this.formValidationButton = !(isFirstNameValid == true && isLastNameValid == true && isEmailValid == true && isAddressValid == true && isAgentValid == true);
        },
    },
    methods: {
        selectCountry(item){
            
            if (item){
                if (this.countryField){
                    if (this.countryField.id == item.id){
                        this.countryField = item
                        this.countryId = item.id
                    }else{
                        this.countryField = item
                        this.countryId = item.id
                        this.cityId = null
                        this.cityField = null
                        this.municipalityField = null
                        this.municipalityId = null
                    }
                }else{
                    this.countryField = item
                    this.countryId = item.id
                    this.cityId = null
                    this.cityField = null
                    this.municipalityField = null
                    this.municipalityId = null
                }
            }else{
                this.countryField = null
                this.countryId = null
                this.cityField = null
                this.cityId = null
                this.municipalityField = null
                this.municipalityId = null
            }
            this.validateForm()
        },
        selectCity(item){
            if (item){
                if (this.cityField){
                    if (this.cityField.id == item.id){
                        this.cityField = item
                        this.cityId = item.id
                    }else{
                        this.cityField = item
                        this.cityId = item.id
                        this.municipalityField = null
                        this.municipalityId = null
                    }
                }else{
                    this.cityField = item
                    this.cityId = item.id
                    this.municipalityField = null
                    this.municipalityId = null
                }
            }else{
                this.cityField = null
                this.cityId = null
                this.municipalityField = null
                this.municipalityId = null
            }

            this.validateForm()
        },
        selectMunicipality(item){
            this.municipalityField = item
            if (item){
                this.municipalityId = item.id
            }else{
                this.municipalityId = null
            }
            this.validateForm()
        },
        selectAgent(item) {
            this.agentField = item
            if (item) {
                this.agentId = item.id
            } else {
                this.agentId = null
            }
            this.validateForm()
        },

        validateFirstName() {
            this.isFirstNameValid = this.firstNameField !== null && this.firstNameField.length > 0;
            this.firstNameValidationMessage = this.isFirstNameValid ? '' : this.$t('error.validation.firstNameRequaerd');
            this.validateForm()
        },
        validateLastName() {
            this.isLastNameValid = this.lastNameField !== null && this.lastNameField.length > 0;
            this.lastNameValidationMessage = this.isLastNameValid ? '' : this.$t('error.validation.lastNameRequaerd');
            this.validateForm()
        },
        validateEmail() {
            var re = /\S+@\S+\.\S+/;
            this.isEmailValid = re.test(this.emailField)
            this.emailValidationMessage = this.isEmailValid ? '' : this.$t('error.validation.emailRequaerd');
            this.validateForm()
        },
        validateAddress() {
            this.isAddressValid = this.addressField !== null && this.addressField.length > 0;
            this.addressValidationMessage = this.isAddressValid ? '' : this.$t('error.validation.addressRequaerd');
            this.validateForm()
        },
        validateForm() {
            var isFirstNameValid = this.firstNameField !== null && this.firstNameField.length > 0;

            var isLastNameValid = this.lastNameField !== null && this.lastNameField.length > 0;
            var re = /\S+@\S+\.\S+/;
            var isEmailValid = re.test(this.emailField)
            var isAddressValid = this.addressField !== null && this.addressField.length > 0;

            var isAgentValid = this.agentField != null

            this.formValidationButton = !(isFirstNameValid == true && isLastNameValid == true && isEmailValid == true && isAddressValid == true && isAgentValid == true);
        },
        submitForm() {
            var data = {}
            data.email = this.emailField
            data.first_name = this.firstNameField
            data.last_name = this.lastNameField
            data.employeeId = this.agentId
            data.phone = this.phoneField
            data.phone2 = this.phoneTwoField
            data.address = this.addressField
            data.countryId = this.countryId
            data.cityId = this.cityId
            data.municipalityId = this.municipalityId

            const json = JSON.stringify(data);
            this.$emit('data', json);
        },
    },
    mounted() {
        if (this.type == "CREATE") {
            this.firstNameField= ""
            this.lastNameField=""
            this.emailField=""
            this.phoneField=""
            this.phoneTwoField=""
            this.addressField=""
            this.clientId=null
            this.agentField=null
            this.agentId=null
            this.countryField = null;
            this.countryId = null
            this.cityField = null
            this.cityId = null
            this.municipalityField = null
            this.municipalityId = null

            this.isFirstNameValid=true
            this.firstNameValidationMessage=null
            this.isLastNameValid=true
            this.lastNameValidationMessage=null
            this.isEmailValid=true
            this.emailValidationMessage=null
            this.isAddressValid=true
            this.addressValidationMessage=null
            this.formValidationButton=true
        }
    }
};

</script>